.spotifyFlex {
    display: flex;
    flex-wrap: wrap;
}

.iFrameDivSpotify {
    margin: 5px;
    height: 380px;
    flex-grow: 1;
    border: 0px;
}

.iFrameDivBandcamp {
    border: 2px solid rgba(255, 255, 255, 0.158);
    height: 540px;
    width: 50vh;
    margin: 5px;
}

.bandcampContainer {
    display: flex;
    justify-content: center;
    align-content: center;
}