.componentStyle {
    margin: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.splashImg {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    /* max-height: 400px; */
    /* min-height: 300px; */
    overflow: hidden;
    object-fit: cover;
    border-radius: 3px;
}