.componentStyle {
        border: 1px solid white;
        /* margin: 25px; */
        padding: 15px;
        /* width: calc(50%); */
        background-color: rgba(0,0,0,0.5);
        /* min-width: 180px; */
        border-radius: 5px;
        }
        
        h1 {
          text-align: center;
        }
        .mainBioContainer {

        }