 .componentStyle {
     padding: 15px;
     background-color: rgba(0, 0, 0, 0.5) !important;  
     border-radius: 3px;
     text-align: center;
     max-width: 70%;
     background-image: none;
     /* width: 40vw;
     min-width: 383px;
     position: absolute;
     left: 5%;
     top: -200px; */
 }

 .componentStyle:hover {
     background-color: rgba(0, 0, 0, 0.5);
 }

 .itemStyle {
    background-color: rgba(0, 0, 0, 0.4) !important;  
 }