.mainPage {
    font-family: "Montserrat", sans-serif;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat");

.pageItems {
    z-index: 2;
}

.particleWrapper {
    z-index: -1;
    position: fixed;
}
