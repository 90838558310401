.portfolioOuterBlock {
    padding: 30px;
    border-radius: 5px;
}

.portfolioInnerBlock {
    padding: 30px;
    border-radius: 5px;
}

.portfolioInnerBlock:hover {
    background-color: rgba(255, 255, 255, 0.158);;
}

.portfolioOuterBlock:hover {
    background-color: rgba(255, 255, 255, 0.158);
}

.portfolioBlock:hover {
    background-color: rgba(255, 255, 255, 0.158);;
}
