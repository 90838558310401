.githubAvatar {
    width: 40px;
    /* transform: translate(0%, 50%); */
    /* margin: 5px; */
    /* object-fit: contain; */
    /* min-height: auto; */
}

.repoList {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    flex-direction: row;
    align-content: stretch;
    row-gap: 2vh;
}

.repoListItem {
    margin-bottom: 1;
    padding: 1;
    border: 2px solid rgba(255, 255, 255, 0.158);
    border-radius: 6px;  
    /* margin: 2;
    width: 23vh;
    flex-grow: 1;
    height: 100% */
}

.repoListItem:hover {
    background-color: rgba(150, 150, 150, 0.158);

}

.noWrap {
    overflow: hidden;
    white-space: nowrap;
}

.coding {
}w

.repoName {
    font-weight: bold;
}

.repoDesc {
    overflow: hidden;
}

.repoInnerCard, .repoInnerCard:link{
    padding: 6px;
    font-weight: lighter;
    font-style: normal;
    text-decoration: none;
    /* color: white;  */
}
